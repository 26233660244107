<div class="pr-dt-crd neum-shadow">
  <div class="image_wrapper">
    <div class="small-image">
      <ngx-slick-carousel
        class="carousel pt-3"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div
          ngxSlickItem
          class="single-image"
          *ngFor="let item of propertyCard.propertyImageUrls; let i = index"
        >
          <img [src]="item" (click)="open(content)" />
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
  <div class="content">
    <h4 class="title-description">{{ propertyCard.propertyName }}</h4>
    <div class="padding-x-16">
      <ngx-slick-carousel
        #slickModal="slick-carousel"
        [config]="slideConfigDescription"
      >
        <div
          ngxSlickItem
          *ngFor="let item of propertyCard.propertyDescription; let i = index"
        >
          <p class="description-text">{{ item }}</p>
        </div>
      </ngx-slick-carousel>
    </div>
    <div class="text-center">
      <ul class="d-flex ppt-btn-ul feature-cards">
        <li class="feature-list">
          <button
            class="neum-shadow btn-custom feature-button"
            [ngClass]="{ 'tab-active': openTab === 'location' }"
            (click)="changeLocationTab('location')"
          >
            Location
          </button>
        </li>
        <li
          class="feature-list"
          *ngIf="propertyCard.amenities !== '$amenities'"
        >
          <button
            class="neum-shadow btn-custom feature-button"
            [ngClass]="{ 'tab-active': openTab === 'amenities' }"
            (click)="changeLocationTab('amenities')"
          >
            amenities
          </button>
        </li>
        <li class="feature-list">
          <button
            class="neum-shadow btn-custom feature-button"
            [ngClass]="{ 'tab-active': openTab === 'houseFeatures' }"
            (click)="changeLocationTab('houseFeatures')"
          >
            House Features
          </button>
        </li>
      </ul>
    </div>
    <div class="location_tab" *ngIf="openTab === 'location'">
      <p *ngFor="let locItem of propertyCard.locationDescription">
        <img [src]="locItem.iconUrl" />{{ locItem.address }}
      </p>
    </div>
    <div class="location_tab" *ngIf="openTab === 'amenities'">
      <p>
        {{ propertyCard.amenities }}
      </p>
      <!-- <p>
        {{ propertyCard.amenities }}
      </p> -->
    </div>
    <div class="home_feature" *ngIf="openTab === 'houseFeatures'">
      <ng-container *ngFor="let val of propertyCard.houseFeatures">
        <div class="main_row" *ngIf="val.featureTitle !== 'null'">
          <h4>{{ val.featureTitle }}</h4>
          <div class="home_tab_row">
            <ng-container *ngFor="let colVal of val.description">
              <div
                class="home_tab_col"
                *ngIf="colVal.value[0] && colVal.value[0] !== 'null'"
              >
                <h5>
                  {{ colVal.title }}
                </h5>
                <p *ngFor="let secVal of colVal.value">
                  {{ secVal }}
                </p>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-body">
    <button
      type="button"
      class="close pp-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <i class="fa fa-close"></i>
    </button>
    <div class="image_wrapper">
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfigPP"
      >
        <div
          ngxSlickItem
          class="single-image"
          *ngFor="let item of propertyCard.propertyImageUrls; let i = index"
        >
          <img [src]="item" (click)="open(content)" />
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</ng-template>
