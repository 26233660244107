<div class="image-replies" *ngIf="carousalStatus">
  <div
    *ngFor="let val of imageReply.data"
    class="reply-box neum-shadow hover-effect"
    (click)="onButtonClick(val.value, val)"
  >
    <div>
      <img
        [ngClass]="{ brightness: checkURL(val.imageUrl) }"
        [src]="val.imageUrl"
      />
    </div>
    <p>{{ val.text }}</p>
  </div>
</div>
