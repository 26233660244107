import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GetApiResponseService } from 'src/app/services/getApiResponse/get-api-response.service';
import { GetButtonIdService } from 'src/app/services/getId/get-button-id.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TypingAnimationService } from 'src/app/services/typingAnimation/typing-animation.service';

@Component({
  selector: 'app-single-image-reply',
  templateUrl: './single-image-reply.component.html',
  styleUrls: ['./single-image-reply.component.scss'],
})
export class SingleImageReplyComponent implements OnInit {
  @Input() imageUrl: any;
  @Input() reply: any;
  clickable = false;

  constructor(
    private getApiResponseService: GetApiResponseService,
    private getButtonIdService: GetButtonIdService,
    private typingAnimationService: TypingAnimationService,
    private storageService: StorageService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const lpg =
      'https://polynomialservices.blob.core.windows.net/polynomialone/61a28341ea7a5b7050207788/lpg.png';
    if (this.imageUrl == lpg) {
      this.clickable = true;
    }
  }

  onClick() {
    if (!this.clickable) return;

    this.getButtonIdService.updatedata(true);
    this.typingAnimationService.update(true);
    this.getApiResponseService.updatedata(this.reply);
    const newData = {
      conversationId: this.storageService.getItem('conversation-id'),
      text: this.reply,
    };

    this.getApiResponseService.getChat(newData).subscribe(
      (data) => {
        this.getApiResponseService.updatechat(data);
        this.typingAnimationService.update(false);
      },
      (err) => {
        console.log('err =====>', err);
      }
    );
  }
}
