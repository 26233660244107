<div class="slider-box neum-shadow" *ngIf="carousalStatus">
  <h4>{{ sliderData.sliderTitle }}</h4>
  <app-range-slider
    [sliderUnit]="sliderData.unit"
    [sliderMaxRange]="sliderData.sliderMaxRange"
    [sliderMinRange]="sliderData.sliderMinRange"
    [sliderRangeData]="sliderData.data"
    [sliderSteps]="sliderData.steps"
    [isVariableUnit]="sliderData.isVariableUnit"
  ></app-range-slider>
  <button class="rounded btn-custom neum-shadow" (click)="onButtonClick()">
    SUBMIT
  </button>
</div>
<!-- hover-effect-->
