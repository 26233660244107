<div class="center-card">
  <div
    class="content-area neum-shadow ticket pb-1 p-4 text-center small-box m-2"
  >
    <h6 class="text-white font-400">{{ loginData.title }}</h6>

    <form action="" class="form-element">
      <div class="form-group">
        <input
          type="text"
          value=""
          autocomplete="off"
          [disabled]="disabled"
          class="form-input mb-2 mt-3"
          name="otpInput"
          [(ngModel)]="otpInput"
          (ngModelChange)="onOTPChange($event)"
        />
        <div
          *ngIf="loginData.resendAction.state && !disabled"
          class="d-flex text-white justify-content-center"
        >
          Didn't receive the code? &nbsp;
          <div
            [ngClass]="{
              'pointer-events-none': disabled,
              'cursor-pointer': !disabled
            }"
            (click)="onButtonClick('RESEND')"
            class="text-dark-pink text-uppercase font-600"
          >
            Resend
          </div>
        </div>
        <p *ngIf="wrongOTP" class="error">OTP entered is incorrect</p>
        <p *ngIf="showResendMessage" class="error">New OTP generated</p>
      </div>
      <div class="d-flex justify-content-center">
        <ng-container *ngIf="!disabled">
          <button
            *ngFor="let action of loginData.action"
            [disabled]="!showSubmit"
            [ngClass]="{ 'pointer-events-none': disabled }"
            class="text-uppercase rounded btn-custom neum-shadow hover-effect"
            (click)="onButtonClick(action.value.data)"
          >
            {{ action.title }}
          </button>
        </ng-container>
      </div>
    </form>
  </div>
</div>
