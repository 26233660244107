<div class="carousal-property">
  <ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    *ngIf="carousalStatus"
  >
    <div
      ngxSlickItem
      class="ngx-item slide"
      *ngFor="let item of corosalData; let i = index"
    >
      <div *ngIf="i % 2 == 0" class="pp-box max-width-400">
        <div
          class="property-item cursor-pointer neum-shadow"
          (click)="onButtonClick(item.value)"
        >
          <div class="col-sm-12 padding-0">
            <div class="row responsive-column">
              <!--style="width:100%;"-->
              <div class="col-xs-3 no-pad">
                <div class="property-image">
                  <img
                    [src]="item.imageURL"
                    onError="this.src='assets/apartment.png'"
                  />
                </div>
              </div>
              <div class="col-xs-9 content-area">
                <div class="content-texts">
                  <h4 class="property-name">{{ item.title }}</h4>
                  <div class="price-tag">
                    <p class="prop-tag mb-1">Starting from</p>
                    <p class="property-price">Rs {{ extractPrice(item) }}</p>
                  </div>
                </div>
                <div class="tags ph-none">
                  <div class="text">{{ extractTags(item) }}</div>
                </div>
              </div>
            </div>
            <div class="tags web-none">
              <div class="text">{{ extractTags(item) }}</div>
            </div>
          </div>
        </div>
        <!-- <i class="fa fa-bookmark-o" aria-hidden="true"></i> -->
        <div
          *ngIf="i + 1 < corosalData.length"
          class="property-item cursor-pointer neum-shadow"
          (click)="onButtonClick(corosalData[i + 1].value)"
        >
          <div class="col-sm-12 padding-0">
            <div class="row responsive-column">
              <div class="col-xs-3 no-pad">
                <div class="property-image">
                  <img
                    [src]="corosalData[i + 1].imageURL"
                    onError="this.src='assets/apartment.png'"
                  />
                </div>
              </div>
              <div class="col-xs-9 content-area">
                <div class="content-texts">
                  <h4 class="property-name">{{ corosalData[i + 1].title }}</h4>
                  <div class="price-tag">
                    <p class="prop-tag mb-1">Starting from</p>
                    <p class="property-price">
                      Rs {{ extractPrice(corosalData[i + 1]) }}
                    </p>
                  </div>
                </div>
                <div class="tags ph-none">
                  <div class="text">{{ extractTags(corosalData[i + 1]) }}</div>
                </div>
              </div>
            </div>
            <div class="tags web-none">
              <div class="text">{{ extractTags(corosalData[i + 1]) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
