import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Options, LabelType } from 'ng5-slider';
import { GetApiResponseService } from '../../services/getApiResponse/get-api-response.service';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
})
export class RangeSliderComponent implements OnInit {
  @Input() sliderMaxRange: string;
  @Input() sliderMinRange: number;
  @Input() sliderRangeData: any = [];
  @Input() sliderUnit: string;
  @Input() sliderSteps: number;
  @Input() isVariableUnit: string;

  value = 0;
  valueOfSlider: any;
  maxRange: any;
  options: any;

  constructor(private getApiResponseService: GetApiResponseService) {}

  ngOnInit(): void {
    this.maxRange = parseInt(this.sliderMaxRange.split('>')[1]);

    this.options = {
      floor: this.sliderMinRange,
      ceil: this.maxRange,
      step: this.sliderSteps ? this.sliderSteps : 2,
      showSelectionBar: true,
      getSelectionBarColor: (value: number): string => {
        this.valueOfSlider = value.toLocaleString('en-IN');
        this.getApiResponseService.updateValue(this.valueOfSlider);
        return '#e63756';
      },
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Floor:
            return this.getSliderUnit(value);
          case LabelType.Ceil:
            return this.getSliderUnit(value);
          default:
            return this.getSliderUnit(value);
        }
      },
    };
  }

  getSliderUnit(value) {
    if (this.isVariableUnit) {
      console.log(value);
      if (this.sliderUnit.toLowerCase() === 'thousand') {
        if (value >= 100) {
          value = value / 100;
          return `${value.toLocaleString('en-IN')} Lakhs`;
        } else if (value >= 1000) {
          value = value / 1000;
          return `${value.toLocaleString('en-IN')} Crore`;
        } else {
        }
      } else if (this.sliderUnit.toLowerCase() === 'lakhs') {
        if (value >= 100) {
          value = value / 100;
          return `${value.toLocaleString('en-IN')} Crore`;
        }
      }
    }
    return '' + value.toLocaleString('en-IN') + ` ${this.sliderUnit}`;
  }
}
